.order-history {
    padding: 0px !important;

    @mixin page-heading-style {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
    }

    .order-history-header {
        padding: 0px !important;

        .order-history-heading {
            @include page-heading-style;
            font-size: 20px;
            line-height: 22px;
            letter-spacing: 1px;
        }

        @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
            justify-content: center;
            align-items: center;
            height: 45px;
            border-bottom: 1px solid #dbdbdbb3;
            opacity: unset;
            margin-bottom: 30px;
        }

        .back-icon {
            transform: rotateY(180deg);
            position: absolute;
            left: 20px;
            top: 15px;
        }

    }
    .order-history-title{
        .order-history-text{
            @include page-heading-style;
            margin: 0px 20px 30px 20px; 
        }
    }

    .order-History-completed-order-section,
    .order-History-current-order-section,
    .order-History-all-order-section {
        padding: 0px !important;
        margin-top: 50px;
        margin-bottom: 30px;

        @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
            margin-top: 0px;
        }

        .order-section-header {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            align-items: center;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            margin-bottom: 15px;
            padding: 0px !important;

            @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                margin: 0 20px;
                font-size: 16px;
            }
        }

        .order-history-order-item {
            background: $Mashed-Potato-Gray;
            position: relative;
            align-items: center;
            margin-top: 10px;
            padding: 0px;
            // padding: 6px 20px;
            // border-top: 2px solid $Light-Gray;

            .order-history-order-item-header-container {
                padding: 0 40px;
                height: 80px;
                align-items: center;
                display: flex;

                @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                    padding: 0 20px;
                }
                @media #{$breakpoint-below-iphone5}{
                    padding: 0 15px;
                }

                .order-history-order-item-header-left-container {
                    display: flex;
                    padding: 0px;

                    .order-history-order-item-heading-section {
                        background: $Dark-Gray;
                        border-radius: 200px;
                        height: 26px;
                        width: auto;
                        min-width: 130px;
                        margin-right: 20px;
                        align-self: center;

                        @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                            width: 27px;
                            height: 26px;
                            min-width: 26px;
                            margin-right: 10px;
                        }

                        .order-item-heading-status-icon {
                            margin: 0 10px 3px;
                            height: 16px;
                            width: 16px;

                            @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                                margin: 6px;
                                margin-top: 3px;
                            }
                        }

                        .order-item-heading-status-msg {
                            font-family: $National2Condensed;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            margin-right: 10px;
                            color: #fff;
                        }
                    }


                    .order-history-order-item-heading {
                        font-family: $National2Condensed;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 25px;
                        align-items: center;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        word-break: break-word;
                        color: $Brand-Black;

                        @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 1px;
                            margin-top: 2.5px;             
                        }
                    }
                }

                .order-history-order-item-header-right-container {
                    cursor: pointer;
                    align-items: center;
                    margin-left: auto;

                    .order-history-order-date-time {
                        font-family: $National2Regular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 22px;
                        align-items: center;
                        letter-spacing: 0.5px;
                        color: $Dark-Gray;
                        margin-right: 20px;
                        text-transform: capitalize;
                        &.red{
                            color:$Red-Orange
                        }

                        @media #{$breakpoint-below-tablet,$breakpoint-below-desktop-above-tablet} {
                            margin: 10px;
                        }
                    }

                    .order-history-track-btn {
                        font-family: $National2Medium;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        //line-height: 24px;
                        align-items: center;
                        letter-spacing: 0.5px;
                        margin: 20px;
                        padding: 11px 25px;
                        color: $Brand-Black;
                        border-style: solid;
                        border-color: $Brand-Black;
                        border-width: 1.5px;
                        background: #00000000;

                        @media #{$breakpoint-below-tablet,$breakpoint-below-desktop-above-tablet} {
                            margin: 10px;
                            margin-left: -35px;
                            padding: 9px 15px;
                        }
                    }

                    .order-history-order-item-accordion-arrow {
                        width: 15px;
                        height: 10px;
                    }
                }
            }
        }

        .order-history-order-item-content {
            padding: 0px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: $Brand-Black;
            box-shadow: 0px 1px 0px $Light-Gray, 0px -1px 0px $Light-Gray;

            .order-history-order-item-panel {
                padding: 20px 40px;
                box-shadow: 0px 1px 0px $Light-Gray, 0px -1px 0px $Light-Gray;
                display: flex;
                align-items: center;

                @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                    padding: 20px;
                }

                .order-history-order-item-img {
                    height: 115px;
                    margin-right: 40px;

                    @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                        height: 80px;
                    }

                }
                .order-history-order-item-img-sopac {
                    height: auto;
                    margin-right: 40px;

                }
                .no-image{
                    @media #{$breakpoint-above-tablet}{
                        width: 17%;
                    }
                }

                .order-history-order-item-details-section {
                    .order-history-order-item-price {
                        margin-top: 10px;
                        display: block;
                    }
                }
            }
        }

        .order-history-order-item-footer-container {
            padding: 20px 40px;
            display: flex;
            align-items: center;

            @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                padding: 20px;
                display: block;
            }

            .order-history-order-item-header-left-container {
                width: auto;

                @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                    width: 100%;
                }
            }

            .order-history-order-receipt-link {
                cursor: pointer;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                align-items: center;
                text-decoration-line: underline !important;
                color: $Brand-Black !important;
                margin-right: 20px;
            }

            .order-history-order-occasion-link {
                cursor: pointer;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                align-items: center;
                text-decoration-line: underline !important;
                color: $Brand-Black !important;
                margin-right: 20px;

                @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                    float: right;
                    margin-right:0px;
                }
            }

            .order-history-order-item-footer-right-container {
                margin-left: auto;

                @media #{$breakpoint-below-tablet, $breakpoint-below-desktop-above-tablet} {
                    margin-top: 25px;

                    .re-order-btn {
                        width: 100%;
                    }
                }
            }

            .order-history-order-favourite {
                height: 17px;
                margin-right: 5px;
            }
        }
    }


    .order-History-Footer {
        padding: 0px !important;
        margin: 48px 0;
        @media #{$mobile-only} {
         margin:86px 0 15px 0;
        }
        .load-more,
        .view-order-count {
            justify-content: center;
            display: flex;
        }

        .footer-btn {
            background: $white;
            display: flex;
            color: $Brand-Black;
            border: 1px solid $Brand-Black;
            margin: 24px 0 100px 0;
        }

        .view-order-count {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.1px;
            color: $Dark-Gray;
            margin-top: 5px;
            margin-bottom: 50px;
            @media #{$mobile-only} {
                margin-bottom: 0;
               }
        }

        .get-help-section {
            display: flex;
            justify-content: space-between;
        }

        .horizontal-bar {
            background: $Gray-light;
            opacity: 0.5;
            margin-bottom: 30px;
        }

        .footer-bootm-left {
            display: flex;
            flex-direction: column;
        }

        .have-a-question {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
            margin-bottom: 5px;
        }

        .connect-specialist {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: $Dark-Gray;
            margin-bottom: 30px;
        }
        .load-more{
            .footer-btn{
                margin: 0 0 10px 0;
            }
        }
    }


    .cart-empty-placeholder {
        margin: 3% 0;
        padding-bottom: 20%;

        @media #{$breakpoint-below-tablet} {
            margin: 3%;
            padding-bottom: 2%;
        }

        & .cart-empty-img {
            width: 37%;
            padding-top: 13%;

            @media #{$breakpoint-below-tablet} {
                width: unset;
                margin: 0 auto;
            }
        }

        .cart-empty-placeholder-view {
            padding-left: 70px;
            padding-right: 0;

            & .cart-empty-placeholder-text {
                @media #{$breakpoint-below-tablet} {
                    margin: 0;
                    padding: 8% 3.5%;
                }
            }

            & .start-order-btn {
                @media #{$breakpoint-below-tablet} {
                    margin-left: 3.5%;
                }
            }
        }
    }

    .order-History-view-receipt-modal {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        overflow-y: scroll;
       
        .modal-body{
            box-shadow: none !important;
        }
        
        .modal-body:focus {
            outline: none !important;
            box-shadow: none !important;
        
        }

        .modal-header {
            padding-left: 0px;
        }

        .modal-content {
            max-height: 80%;

            @media #{$breakpoint-below-tablet} {
                max-height: 100%;
            }
        }

        .order-processing-info-container {
            padding: 0px;
        }

        .header-title {
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
            text-align: left;

            @media #{$breakpoint-below-tablet} {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: $Dark-Gray;
                width: 100%;
            }
        }

        .custom-modal-close {
            top: auto !important;
        }

        .orderId {
            padding: 0px;
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $Brand-Black;
        }

        .orderFrom {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $Brand-Black;
            margin-top: 35px;
        }
        .place-direction{
            display: block;
        }
        .place {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $Brand-Black;
            padding-top: 10px;
            overflow-wrap: break-word;
            display: grid;
            .storeName{
                font-family: $National2Medium;
            }

        }

        .Address {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #494949;
        }

        .ContactDetail {
            display: flex;
            justify-content: space-between;
            padding-top: 10px;

            .phoneNum {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-decoration: underline;
                cursor: pointer;
            }

            .anchorTag {
                color: black;
            }

            .phoneIcon {
                padding-right: 10px;
            }
        }

        .global-accordian-container.billDropDown .global-accordian-header-container .global-accordian-arrow {
            width: 12px;
            height: 12px;
        }

        .line {
            margin: 30px 0px;
        }

        .hr-payment-type {
            height: 1px;
            margin: 15px 0;
            background: #E3E3E3;
        }

        .postorder-payment-details {
            height: 18px;
            line-height: 16px;
            margin-top: 20px;

            & .payment-header {
                font-family: $National2Condensed;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.5px;
                text-align: left;
            }

            &.payment-details {
                margin-top: 10px;
                line-height: 10px;
                margin-bottom: 20px;

                &.payment-completed {
                    font-family: $National2Regular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: 0px;
                    text-align: left;
                }
            }
        }
    }
}
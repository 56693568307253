@import "_colors";
//font family variables
$HelveticaNeue: "Helvetica Neue";
$National2Test: "National 2 Test";
$National2Regular: "National 2 Regular";
$National2CondensedWebMedium: "National 2 CondensedWeb-Medium";
$National2Condensed: "National 2 Condensed";
$National2TestRegular: "National2Test-Regular";
$National2Medium: "National2-Medium";
$National2: "National 2";
$National2TestMedium: "National2Test-Medium";
$MediumNational2: "National2 Medium";
$National2ExtraBold :"National2Test-Extrabold";
$Sentinel: "Sentinel";

// Opacity Variables
$opacity-half: 0.5;
$opactity-full: 1;

// z-index Variables
$z-index-max: 9999;
$z-index-min: 1;
$z-index-header: 10;
$z-index-navigation: 5;
$z-index-negative: -1;

//header-height
$header-height-mobile: 45px;
$header-height-desktop: 100px;

$text-direction: rtl !default;
//font weight
$font-weight-normal: normal;

/* 300 */
$font-weight-bold: bold;

$site-name        : 'site';

$root-header      : 'root-header';
$root-main        : 'root';
$root-footer      : 'root-footer';
$page-home        : 'page-home';

$header-sign-in   : 'header-sign-in';
$checkout-sign-in : 'checkout-sign-in';
$checkout-help-icon: 'checkout-help-icon';

$payment-option   : 'payment-option';
$payment-google   : '#{$payment-option}-google-pay-spot';
$payment-phone-pe : '#{$payment-option}-phonepe';
$payment-pay-tm   : '#{$payment-option}-paytm';

$channel-debug    : 'channel-debug';
$channel-browser  : 'channel-browser';
$channel-google   : 'channel-google-pay';
$channel-phone-pe : 'channel-phone-pe';
$channel-pay-tm   : 'channel-pay-tm';

$animation-type   : all;
$animation-style  : ease-out;
$animation-speed  : 0.3s;
$animation-delay  : 0.4s;
$ease-out		  : cubic-bezier(0.15, 0.45, 0.6, 1);

$max-loop-count   : 25;

/* 500 */
$font-weight-500: 500;

/* 500 */
$font-weight-400: 400;

//generic variables
$d-none: none;
$d-inline-flex: inline-flex;
$d-flex: flex;
$pos-abs: absolute;
$pos-rel: relative;
$center: center;
$left: left;
$right: right;
$uppercase: uppercase;
$underline: underline;
// Media Queries
$mobile-only: "(max-width: 650px)";
$breakpoint-between-mob-tablet: "(min-width: 651px) and (max-width: 769px)";
$medium-only: "(min-width: 768px) and (max-width: 991px)";
$breakpoint-below-medium-only: "(max-width: 990px)";
$large-only: "(min-width: 992px) and (max-width: 1199px)";
$breakpoint-below-tablet: "(max-width: 1023px)";
$breakpoint-above-tablet: "(min-width: 1023px)";
$breakpoint-between-tablet-desktop: "(min-width: 1200px) and (max-width: 1439px)";
$breakpoint-below-iphone5: "(min-width: 320px)  and (max-width: 359px)";
$minMozDevicePixelRatio: "min--moz-device-pixel-ratio: 0";
$breakpoint-tablet-only: "(min-width: 651px) and (max-width: 1023px)";
$mobile-tablet-only: "(max-width: 769px)";
$breakpoint-above-desktop: "(min-width: 1441px)";
$breakpoint-above-medium-desktop: "(min-width: 1400px)";
$breakpoint-below-desktop: "(max-width: 1441px)";
$breakpoint-below-and-above-medium-desktop: "(min-width: 1400px) and (max-width: 1441px)";
$breakpoint-below-desktop-above-tablet: "(min-width: 1023px) and (max-width: 1200px)";
$breakpoint-below-include-iphone5: "(min-width: 320px)  and (max-width: 361px)";
$breakpoint-below-include-iphone5SE: "(max-width: 321px)";
$breakpoint-below-include-iphoneX:"(max-width: 380px)";
$breakpoint-above-destop-wide: "(min-width: 1601px)";
$breakpoint-below-iphoneX:"(max-width: 370px)";
$breakpoint-iphone-ios15: "(min-width: 371px) and (max-width: 376px)";
$breakpoint-iphone-plus: "(min-width: 391px) and (max-width: 426px)";
$breakpoint-iphone-size: "(min-width: 385px) and (max-width: 390px)";
$breakpoint-iphone12-size: "(min-width: 374px) and (max-width: 390px)";
$breakpoint-iphone-six-plus: "(min-width: 414px) and (max-width: 736px)";
$breakpoint-above-destop-wide-height: "(min-height: 1000px)";
$breakpoint-above-destop-low-height: "(min-height: 900px) and (max-height: 1024px)";
$breakpoint-samsung-galaxy-tab-s7-landscape-height: "(min-height: 700px) and (max-height: 800px)";
$breakpoint-above-large-desktop: "(min-width: 1540px) and (max-width: 1920px)";
$breakpoint-below-desktop-height: "(max-height: 700px)";
$breakpoint-samsung-galaxy-tab-s7: "(min-width: 753px) and (max-width: 754px)";
$breakpoint-samsung-galaxy-tab-s7-landscape: "(min-width: 1037px) and (max-width: 1038px)";
$breakpoint-ipad-landscape: "(min-width: 834px) and (max-width: 835px)";
$breakpoint-ipad-12-pro-portrait: "(min-width: 1024px) and (max-width: 1025px)";
$breakpoint-ipad-8-landscape: "(min-width: 1080px) and (max-width: 1081px)";
$breakpoint-ipad-pro-12-landscape: "(min-width: 1365px) and (max-width: 1366px)";
$breakpoint-iphone-12-pro-landscape: "(min-width: 896px) and (max-width: 897px)";
$breakpoint-iphone-12-pro-portrait: "(min-width: 414px) and (max-width: 415px)";
$breakpoint-ipad-pro-11-landscape: "(min-width: 1075px) and (max-width: 1076px)";
$breakpoint-iphone-11-pro-portrait: "(min-width: 375px) and (max-width: 376px)";
$breakpoint-medium-desktop: "(min-width: 1280px) and (max-width: 1281px)";
$breakpoint-between-ipad-tablet-and-medium-desktop: "(min-width: 733px) and (max-width: 1281px)";
$breakpoint-below-medium-desktop: "(min-width: 1200px) and (max-width: 1399px)";
$breakpoint-above-medium-tablet: "(min-width: 1024px)";
$breakpoint-above-tablet-and-below-medium-desktop: "(min-width: 1024px) and (max-width: 1281px)";
$breakpoint-ipad-pro-tablet: "(min-width: 1024px) and (max-width: 1366px)";
$breakpoint-samsung-galaxy-s7-tab:"(min-device-width: 1205px) and (max-device-width: 1206px)";
$breakpoint-ipad-pro-11:"(min-device-width: 834px) and (max-device-width: 835px)";
$breakpoint-ipad-pro-11-oreintation-landscape:"(min-width: 1194px) and (max-width: 1195px)";
$breakpoint-samsung-s21plus:"(min-width: 393px) and (max-width: 394px)";
$breakpoint-s21plus-samsung:"(min-width: 384px) and (max-width: 385px)";
$breakpoint-samsung-s20:"(min-width: 360px) and (max-width: 361px)";
$breakpoint-googlepixel-5:"(min-width: 393px) and (max-width: 394px)";
$breakpoint-iphone13:"(min-width: 390px) and (max-width: 391px)";
$breakpoint-iphone-se-2020:"(min-width: 375px) and (max-width: 376px)";
$breakpoint-iphone-se-2022:"(min-width: 375px) and (min-height: 548px)";
$breakpoint-desktop-small:"(min-width: 1024px) and (max-width: 1024px)";
$breakpoint-ipad-air-portrait: "(min-width: 820px) and (max-width: 1180px)";
$breakpoint-ipad-mini-portrait: "(min-width: 768px) and (max-width: 1024px)";
$breakpoint-above-destop-large-desktop: "(min-width: 1024px) and (max-width: 1920px)";
$breakpoint-above-destop-wide-large-screen: "(min-width: 1921px)";
$breakpoint-above-destop-below-large-desktop: "(min-width: 1024px) and (max-width: 1439px)";
$breakpoint-macbook-pro-desktop: "(min-width: 1600px) and (max-width: 1601px)";
$breakpoint-macbook-pro-large-desktop: "(min-width: 1680px) and (max-width: 1681px)";
$breakpoint-iphone13-pro-max: "(min-width: 428px) and (max-width: 429px)";
$breakpoint-between-mob-ipad: "(max-width: 768px)";
$breakpoint-between-ipad-tablet: "(min-width: 769px) and (max-width: 1023px)";

.headerLogoMobile {
	cursor: pointer;
	margin: auto;
}
*:focus-visible{
	outline: rgb(229, 151, 0) auto 1px;
}
.cartIcon {
	width: 3.2rem;

	img {
		max-width: 100%;
	}
}

.iconActive {
	transform: scale(1.2);
	@media #{$breakpoint-above-tablet} {
		padding-left: 5px !important;
	}
	img {
		width: 100% !important;
    min-width: 1rem !important;
    max-width: 3rem !important;
    margin: auto !important;
    margin-right: 5px !important;
	}
}

.itemCountActive {
	margin-top: 10%;
	@media #{$breakpoint-above-tablet} {
		margin-top: 5%;
	}
}

.mobileIconCountActive {
	font-size: 8px;
	position: absolute;
	margin-top: 6%;
	right: 14px !important;
}

.margin-auto {
	margin: auto;
}

.display-none {
	display: none !important;
}

.Toastify__toast-container{
	z-index: 99999;
}

.cartIconCount,
.mobileCartIconCount {
	position: absolute;
	top: 0;
	right: 22px;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 15px;
	font-size: 13px;
	text-align: center;
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;

	@media #{$breakpoint-below-tablet} {
		right: 19px;
		&.hamburger-count {
			right: 18px !important;
		}
	}

	.cart-icon-plus {
		position: relative;
		left: 6px;
	}

	span.cartPlus {
		margin-top: -2px;
		margin-right: 2px;
		&.hamburger-plus {
			margin-left: 2px;
		}
	}
}

@mixin slickCommons {
	.slick-list {
		.slick-slide {
			opacity: 0.25;

			&.slick-active {
				opacity: 1;
			}
		}
	}
}

@include slickCommons();

@mixin backgroundImage {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

@mixin imageAnimation {
	transform: scale(0.9);
	transition: all .5s cubic-bezier(0.4, 0, 0.2, 1);
	animation-name: cf3FadeInOut;
	animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	animation-duration: 0.5s;
	animation-direction: alternate;
}

@keyframes cf3FadeInOut {
	0% {
		opacity:0;
	}
	50% {
		opacity:0.75;
	}
	100% {
		opacity:1;
	}
}

@mixin Dot {
	.Dot {
		background-color: #494949;
		height: 4.5px;
		width: 4.3px;
		display: inline-block;
		border-radius: 30px;
		margin-left: 4px;
		margin-bottom: 2px;
	}
}

@mixin language-selector-comp-styles($withBorder, $color) {
  @if $withBorder {
    .language-selector-container {
      width: 89px;
      height: 36px;
      margin-left: auto;
      .language-selector {
        width: 89px;
        height: 36px;
        background-color: transparent;
        outline: none;
        border: none;
        .language-select {
          width: 91px;
          height: 36px;
          margin: 0;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
          color: $color;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: "";
          padding: 0 37px 0 28px;
          border-radius: 34px;
          text-transform: uppercase;
          background-color: transparent;
          pointer-events: none;
        }
        .dropdown-img {
          position: relative;
          vertical-align: middle;
          top: -33px;
          left: 36px;
        }
        .dropdown-img-desktop {
          display: none;
        }
      }
    }
  } @else {
    .language-selector-container {
			width: 24.5%;
			padding-left: 0;
			display: flex;
			border-right: 1px solid $Light-Gray;
			height: 24px;
			margin: auto 0;
			.language-selector {
				background-color: transparent;
				padding: 0;
				outline: none;
				border: none;
				.language-select {
					background-color: #fff;
					border: none;
					width: max-content;
					min-width: 25px;
					padding: 0;
					text-transform: capitalize;
					pointer-events: none;
					font-family: $National2Medium;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					color: $Brand-Black;
					-webkit-appearance: none;
					-moz-appearance: none;
					text-indent: 1px;
					text-overflow: "";
				}
				.dropdown-img {
					display: none;
				}
				.dropdown-img-desktop {
					width: 5px;
					height: 2.5px;
					vertical-align: middle;
					margin-left: 12px;
				}
			}
			@media #{$breakpoint-below-tablet} {
				display: none;
			}
		}
		.language-selector-container.no-border {
			border: none;
		}
  }
}
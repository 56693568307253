@import 'AU/_orderProcessingSummary';

.orderProcessingPage,
.orderSummaryPage,
.trackOrderPage,
.order-history {
    .header {
        background: #fff;
        padding: 2% 2%;
        z-index: 9;
        border-bottom: 1px solid #dbdbdb;
    }

    .closeIcon {
        margin-left: 86px;
    }

    .roundedQuesmark {
        top: 5px;
    }

    .order-processing-info-accordion-section {
        display: flex;

        // margin-top: 50px;
        @media #{$breakpoint-below-tablet} {
            display: unset;
        }

        .order-processing-info-accordion {
            margin-top: 20px !important;
        }
    }

    .orderSummaryContainer {
        background: #fff;
        height: auto;
        margin-bottom: 50px;

        .distaIframeContainer-delivered,
        .distaIframeContainer-cancelled {
            background: $white !important;
        }

        .distaIframeContainer-delivered {
            margin: 40px auto !important;
        }

        .orderSummaryStatusContainer {
            display: flex;
            justify-content: center;
            position: relative;
            background: #f8f7f5;
            height: 589px;

            &.distaIframeContainer {

                iframe {
                    border: none;
                    height: 589px;
                }
            }


            @media #{$breakpoint-below-tablet} {
                display: flex;
                justify-content: center;
                position: relative;
                background: #f8f7f5;
                height: 393px;

                &.distaIframeContainer {

                    iframe {
                        height: 502.5px;
                    }
                }
            }

            .orderSummaryStatusPanel {
                margin-top: 70px;
                text-align: center;

                img {
                    width: 58px;
                    height: 70px;
                }

                @media #{$breakpoint-below-tablet} {
                    margin-top: 50px;
                }
            }

            .allSet {
                font-family: $National2Condensed;
                font-size: 74px;
                font-style: normal;
                font-weight: 700;
                line-height: 78px;
                letter-spacing: 2px;
                text-align: center;
                margin-top: 20px;

                @media #{$breakpoint-desktop-small}{
                    font-size: 65px;
                }
                @media #{$breakpoint-below-include-iphone5}{
                    font-size: 35px !important;
                }

                @media #{$breakpoint-below-tablet} {
                    font-weight: bold;
                    font-size: 42px;
                    line-height: 46px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }

                &.pickup {
                    &.cancelled-allSet {
                        padding: 0px 150px;

                        @media #{$breakpoint-below-tablet} {
                            padding: 0px 45px;
                        }
                    }

                    .allSet-pickup-refund {
                        font-size: 20px;
                        line-height: 22px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        padding: 20px 146px;

                        @media #{$breakpoint-below-tablet} {
                            padding: 20px 45px;
                        }
                    }
                }
            }

            .idAndTime {
                font-family: $National2Condensed;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-align: center;
                margin-bottom: 40px;
                margin-top: 15px;
                color: $Brand-Red;

                @media #{$breakpoint-below-tablet} {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 22px;
                    letter-spacing: 1px;
                }
            }
            .allset-delivery {
                padding: 0 4% !important;
                margin: 30px 0px 70px;
                @media #{$breakpoint-desktop-small}{
                    padding: 0px 3% !important; 
                }
                @media #{$breakpoint-above-medium-desktop} {
                    padding: 0 4% !important;
                } 
                @media #{$breakpoint-below-include-iphone5} {
                    padding: 0% 2% !important;
                }     
            }
             .delivery_created-delivery-allSet{
                @media #{$breakpoint-below-tablet} {
                    margin: 20px 0px 70px;    
                    padding: 0% 6% !important;               
                }
                       
             }
             .dasher_confirmed_store_arrival-delivery-allSet{
                @media #{$breakpoint-below-tablet} {
                    margin: 20px 0px 70px;    
                    padding: 0% 7% !important;               
                }               
             }

            .time-delivery {
                margin-top: 5px;
                margin-bottom: 30px;

                @media #{$breakpoint-below-tablet} {
                    margin-bottom: 20px;
                }

                & .otp {
                    @media #{$breakpoint-below-tablet} {
                        font-size: 42px;
                        line-height: 46px;
                    }
                }
            }

            .red {
                color: $Brand-Red;
            }

            .DELIVERED-delivery,
            .white,
            .DELIVERED-delivery-allSet {
                color: $white !important;
            }

            .ASSIGNED-delivery-allSet {
                padding: 0 16% !important;
            }


            .estimated-arrival-refund {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                text-align: center;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: $Dark-Gray;

                @media #{$breakpoint-below-tablet} {
                    letter-spacing: 1px;
                }
            }

            .estimated-arrival {
                font-size: 20px;
                line-height: 24px;

                @media #{$breakpoint-below-tablet} {
                    line-height: 22px;
                }
            }

            .refund-text {
                font-size: 24px;
                padding: 0 23%;

                @media #{$breakpoint-below-tablet} {
                    font-size: 20px;
                    line-height: 22px;
                    padding: 0px;
                }
            }

            .driver-msg {
                @media #{$breakpoint-below-tablet} {
                    margin: 5% 5% 0 10%;
                    padding: 0 10%;
                }
            }
        }

        .orderSummaryStatusContainer::before {
            content: '';
            position: absolute;
            height: 26px;
            width: 50px;
            bottom: 98%;
            border-radius: 0 0 100px 100px;
            background: $white;
        }

        .orderSummaryStatusContainer::after {
            content: '';
            position: absolute;
            height: 26px;
            width: 50px;
            top: 98%;
            border-radius: 100px 100px 0 0;
            background: $white;
        }

        .redCard {
            background: $Brand-Red;
            color: $white;

            .estimated-arrival-refund {
                color: $white;
            }

            .idAndTime {
                color: $white;
            }
        }

        .delivery-raider-info {
            margin: 20px 0px;
            padding: 30px 40px;
            min-height: 120px;
            background-color: $Dark-Gray;

            @media #{$breakpoint-below-tablet} {
                padding: 20px 20px;
            }

            .head-text {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold !important;
                font-size: 20px !important;
                line-height: 22px !important;
                letter-spacing: 1px !important;
                text-transform: uppercase;
                color: $white;
            }

            .contact-call {
                margin-left: 15px;
                float: right;
            }

            .contact-text {
                float: right;
            }

            .contact-text {
                margin-left: 10px;
                font-family: $National2TestRegular;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;

                .rider-name {
                    float: right;
                }

                &.call {
                    text-decoration-line: underline;
                }

                &.text {
                    text-decoration-line: underline;
                }
            }

            .left-sec {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $white;

                span.rider-name {
                    font-weight: 500;
                    font-family: $National2Medium;
                }
            }

            .rgt-sec {
                margin: 5px 0;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;

                @media #{$breakpoint-below-tablet} {
                    margin: 0px 0px 0px 10px;
                }
            }
        }

        .dis-flex-first {
            display: flex;

            @media #{$breakpoint-below-tablet} {
                display: unset;
            }
        }

        .set-opacity {
            opacity: 0.7;
        }

        .contact-text-mob {
            margin-left: 10px;
            font-family: $National2TestRegular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            cursor: pointer;
            color: #FFFFFF;
        }

        .algn-rgt {
            text-align: right;
        }

        .algn-left {
            text-align: right;

            @media #{$breakpoint-below-tablet} {
                text-align: unset;
            }
        }

        .hr-line {
            height: 1px;
            opacity: 0.25;
            margin: 15px 0;
            background: #FFFFFF;
        }

        .dis-flex-sec {
            display: flex;
        }

        .mr-set {
            margin: 5px 0;
        }

        .redCard {
            background: $Brand-Red;
        }

        .order-processing-info-accordion {
            margin-left: 20px; //130px;
            // margin-bottom: 50px;
            margin-top: 40px;
            position: relative;
            height: fit-content;

            @media #{$breakpoint-below-tablet} {
                margin-left: 12px;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .order-processing-info-container.global-accordian-container {
                border: unset;
            }

            .order-processing-info-container>.global-accordian-header-container {
                height: unset;
                padding: unset;
            }

            .accordion-Title {
                // margin-top: 39px;
                justify-content: space-between;
                padding: 10px 30px 30px 30px;

                @media #{$breakpoint-below-tablet} {
                    padding: 18px 20px 37px;
                }
            }

            .order-processing-info-container {
                border: unset;
                margin-top: unset !important;
                padding-left: unset !important;
                padding-right: unset !important;
            }

            .order-processing-info-accordian {
                .global-accordian-header:first-of-type {
                    font-family: $National2Condensed !important;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 1px;
                    text-align: center;
                }

                .global-accordian-header {
                    font-family: $National2Condensed !important;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.5px;
                    text-align: center;
                }
            }
        }

        .order-processing-info-accordion-info {
            border: 1px solid #202124;
            margin: 0;
            padding: 0;
        }

        .orderTitle {
            margin-top: 30px;
            padding: 0px;

            @media #{$breakpoint-below-tablet} {
                margin-top: 43px;
            }

            .yourOrder {
                padding: 0px;
                font-family: $National2Condensed;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-align: left;
                text-transform: uppercase;
            }

            .OrderClass {
                padding: 0px;
                text-transform: uppercase;
            }

            .orderId {
                padding: 0px;
                font-family: $National2Condensed;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.5px;
                text-align: center;
                float: right;
            }
        }

        .orderingDateTime {
            font-family: $National2Regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            height: 18px;
        }

        .postorder-payment-details {
            height: 18px;
            line-height: 16px;
            margin-top: 20px;

            & .payment-header {
                font-family: $National2Condensed;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.5px;
                text-align: left;
            }

            &.payment-details {
                margin-top: 10px;
                line-height: 10px;
                margin-bottom: 20px;

                &.payment-completed {
                    font-family: $National2Regular;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: 0px;
                    text-align: left;
                }
            }
        }

        .orderFrom {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #202124;
            margin-top: 35px;
        }

        .place {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $Brand-Black;
            padding-top: 10px;
            overflow-wrap: break-word;
        }

        .fnt-bld {
            font-weight: bold;
        }

        .hrs-view {
            letter-spacing: -0.1px;
            color: #494949;
            padding-left: 10px;
        }

        .mob-display {
            @media #{$breakpoint-below-tablet} {
                display: flex;
                padding-left: 0px;
            }
        }

        .Address {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #494949;
        }

        .ContactDetail {
            display: flex;
            justify-content: space-between;
            padding-top: 10px;

            .phoneNum {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-decoration: underline;
                cursor: pointer;
            }

            .anchorTag {
                color: black;
            }

            .phoneIcon {
                padding-right: 10px;
            }
        }

        .price {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
        }

        .priceList {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
        }

        .fonts {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #494949;
            text-transform: capitalize;
        }

        .amount {
            font-family: $National2Regular;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: right;
            color: $Brand-Black;
        }

        .total {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
        }

        .payment-type {
            font-family: $National2Regular;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #494949;
            margin-top: 5px;
            text-transform: capitalize;
        }

        .totalAmount {
            display: flex;
            justify-content: space-between;
            margin-top: 19.82px;
            margin-bottom: unset;
        }

        .mt-5 {
            margin-top: 5px !important;
        }

        .hr-payment-type {
            height: 1px;
            margin: 15px 0;
            background: #E3E3E3;
        }

        .ViewMore {
            font-family: $National2TestRegular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            text-decoration-line: underline;
            color: $Brand-Black;
            margin-top: 10px;
            margin-bottom: 21px;
        }

        .line {
            margin: 15px 0px;
        }

        .global-accordian-container .global-accordian-header-container {
            height: 60px;
            padding: 19px;
            padding-left: 6px;
        }

        .global-accordian-container {
            border-top: 2px solid black;
            border-bottom: 2px solid black;
        }

        .global-accordian-container .global-accordian-header-container .global-accordian-arrow {
            width: 12px;
            height: 12px;
        }

        .global-accordian-container .global-accordian-header-container .global-accordian-header {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: $Brand-Black;
        }

        .number {
            background: #e3e3e3;
            border-radius: 4px;
            padding-right: 4px;
            padding-left: 4px;
            margin-right: 3px;
        }

        .foodName {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $Brand-Black;
        }

        .ItemPrice {
            text-align: right;
        }

        .order-processing-info-description {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 31px;
        }
    }
    .order-History-view-receipt-modal-container{
        padding: 0px 20px 0px 20px;
    }
    .order-processing-info-container {
        position: relative;
        border: unset;
        background: #fff;
        height: auto;

        .bottom-line{
            border-bottom: 1px solid $Light-Gray;
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
        }
    }
    .SavedNotification {
        width: 265px;
        height: 62px;
    }

    .toast-for-favorite {
        margin: auto 0 !important;
        padding: 6px !important;
    }

    .notifyText {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #fff;
        padding: 0px 0px 0px 35px;

        @media #{$mobile-only} {
            padding: 0px 0px 0px 2px;
        }
        &.notifyText-in {
            justify-content: center;
            padding-left: 0;
        }
    }

    .TickImg {
        padding-right: 10px;
    }

    .notify {
        display: flex;
        justify-content: flex-end;
        margin-top: -32px;
        margin-right: -111px;

        @media #{$breakpoint-below-tablet} {
            display: flex;
            justify-content: center;
            position: absolute;
            margin-top: 0;
            margin-right: 0;
            margin-left: 40px;
        }
    }

    // TrackOrderScreen Error Message Display
    .inputbox {
        &.trackOrderScreen {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .modal-coupon-input-field {
                width: 330px;
            }

            & #saveTrackOrderScreen {
                width: 380px;
            }
        }

        +.buttonItems {
            margin-top: 3.5%;
        }
    }

    #saveTrackOrderScreen {
        @media #{$mobile-only} {
            margin-left: 0%;

            +.buttonItems {
                margin-top: 20px;
            }
        }

        @media #{$breakpoint-tablet-only} {
            margin-left: 22%;

            +.buttonItems {
                margin-top: 20px;
            }
        }
    }

    .circle-topp {
        height: 19px;
        width: 42px;
        border-radius: 0 0 200px 200px;
        background: #fff;
        margin: -2px auto auto;
        border-bottom: 1px solid #202124;
    }

    .circle-bottomm {
        height: 19px;
        width: 42px;
        border-radius: 200px 200px 0 0;
        background: #fff;
        bottom: -1px;
        left: calc(50% - 21px);
        position: absolute;
        margin: 0 auto;
        border-top: 1px solid #202124;
    }

    .flexBetweens {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 6px;
    }

    .checkout-summary-content {
        border-bottom: 1px solid $Light-Gray;
        padding-bottom: 1.25rem;
        margin-bottom: 1.25rem;
        margin-top: 15px;

        &-item {
            &-name {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: $Dark-Gray;

                .cart-discount-code {
                    white-space: nowrap;
                    font-weight: 500;
                    margin-right: 5%;
                }

                .cart-discount-clear-icon {
                    vertical-align: middle;
                    cursor: pointer;
                }

                &-total {
                    font-family: $National2Condensed;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: $Brand-Black;
                }
            }

            &-value {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $Brand-Black;

                &-total {
                    font-family: $National2Condensed;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: $Brand-Black;
                }
            }
        }
    }

    .checkout-add-hope-container {
        display: flex;
        font-size: small;
        margin-bottom: 20px;
        border: 1px solid #e3e3e3;
        border-radius: 3px;
        padding: 10px 16px;

        .checkout-add-hope-checkbox {
            margin-top: 5px;
            margin-right: 5px;
        }

        .checkout-add-hope-content {
            margin-right: 5px;
            margin-left: 5px;

            .checkout-add-hope-header {
                width: 159px;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 900;
                font-size: 14px;
                line-height: 24px;
                color: $Brand-Black;
            }

            .checkout-add-hope-text {
                width: 163px;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 22px;
                color: $Dark-Gray;
            }
        }
    }

    .checkout-add-bag-container {
        display: flex;
        font-size: small;
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 1.25rem;
        margin-bottom: 1.25rem;

        .checkout-add-bag-checkbox {
            margin-top: 5px;
            margin-right: 5px;
        }

        .checkout-add-bag-content {
            margin-right: 5px;
            margin-left: 5px;

            .checkout-add-bag-text {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 900;
                font-size: 14px;
                line-height: 24px;
                color: $Brand-Black;
            }
        }
    }

    #statusMsg {
        margin-top: 20px;
        font-family: $National2Condensed;
        font-size: 17px;
        margin-bottom: 20px;
    }

    .linebreak {
        padding: 0px;
        height: 1.08px;
        background-color: $Light-Gray;
        margin: auto;
        margin-bottom: 20px;
    }

    .saving {
        font-family: $National2Condensed;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-align: left;

        @media #{$breakpoint-below-tablet} {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 1px;
            margin-bottom: 10px;
        }
    }

    .savingdiv {
        display: flex;
        padding-top: 50px;
        margin: auto;

        @media #{$breakpoint-below-tablet} {
            display: inline-block;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .signup {
        @media #{$breakpoint-below-tablet} {
            margin-top: 20px;
        }
    }

    .saveButton {
        float: right;

        @media #{$breakpoint-below-tablet} {
            float: none;
        }
    }

    .orderProcessingProgressBar {
        display: flex;
        justify-content: center;
        min-height: 65vh;
        border-radius: 0;
        background-color: $Brand-Black;
        border-bottom: 1px solid $Dark-Gray;
        padding: 0px;

        .orderProcessingProgressBarContainer {
            padding: 0px;

            .current-order-status {
                font-family: $National2Condensed;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.5px;
                text-align: center;
                color: #f8f7f5;
                padding-top: 14px;
                padding-bottom: 27px;

                @media #{$breakpoint-below-tablet} {
                    font-size: 20px;
                    line-height: 22px;
                    letter-spacing: 1px;
                    color: #FFFFFF;
                }
            }

            .progress-bar-text {
                font-family: $National2Condensed;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.5px;
                text-align: center;
                color: #f8f7f5;
                padding-top: 23px;
                padding-bottom: 84px;

                @media #{$breakpoint-below-tablet} {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 1px;
                    color: #E3E3E3;
                }
            }

            .progress-bar-cont {
                justify-content: center;
                display: flex;
            }

            .progress-bar-container {
                padding: 0px;
                height: 12px;
                border-radius: 20px;
                background: repeating-linear-gradient(65deg, #222 4px, #222 6px, #333 6px, #333 8px);
            }

            .progress-bar {
                width: 10px;
                height: 12px;
                background-color: #e4002b;
                border-radius: 60px;
                transition: width 6s;
            }

            .confirm-order-text {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 64px;
                line-height: 76px;
                text-align: center;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #fff;
            }

            .confirm-order-text-cont {
                display: flex;
                justify-content: center;
            }
        }

        @media #{$breakpoint-below-tablet} {
            height: 336px;

            .orderProcessingProgressBarContainer {

                .progress-bar-container {
                    height: 10.19px;
                }

                .progress-bar {
                    height: 10.19px;
                }

                .confirm-order-text {
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 36px;
                    letter-spacing: 0;
                }

                .progress-bar-text {
                    padding-top: 17px;
                    padding-bottom: 57px;
                }
            }
        }
    }

    .progress-bar-list {
        display: flex;
        justify-content: space-around;
        margin: 0 12%;

        &.ProgressDelivery {
            width: 15%;
            height: 12px;
            border-radius: 20px;
            margin-top: 20px;
        }

        &.Bar {
            background: repeating-linear-gradient(65deg, transparent 4px, transparent 6px, $Dark-Gray 6px, $Dark-Gray 8px);
        }

        &.selectedBar {
            background-color: #e4002b;
        }
    }

    .delivery-raider-info {
        .contact-name-text {
            // padding: 0px;
            margin: 0px;
            font-family: $National2TestRegular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #fff;
            display: flex;
            justify-content: right;

            @media #{$breakpoint-below-tablet} {
                padding: 0px 10px 0px 15px;
                justify-content: left;

                .rider-name {
                    float: left !important;
                }
            }
        }

        .rgt-sec {
            justify-content: right;

            @media #{$breakpoint-below-tablet} {
                padding: 0px;
            }
        }
    }

    .save-order-modal .modal-content {
        width: 45%;

        .modal-header {
            height: 50px;
        }
    }

    .saveButton {
        button {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            //line-height: 24px;
            color: $Brand-Black;

            img {
                padding-right: 10px;
            }
        }
    }

    .shared-modal-container.map-modal, .orderSummaryContainer {
        .googleMapContPanel {
            width: 100%;
            height: 100%;
        }

        .googleMapContainer {
            height: 100%;
        }

        .use-location-icon {
            position: absolute;
            z-index: 1;
            margin: 10px;
            img{
            width:32px;
            height:32px;
        }
        }
    }
}
